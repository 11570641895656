import { isAuthenticated, getLoginRedirectUrl } from './self'
import axios from 'axios'

// This method will need to be revisited. Presently it is hard coded to the dev api gateway and should be getting that from an environment config.
// Likely this type of method call should be pulled into its own generic space as more functionality is added to the dev portal.
// For testing purpose
export const getDeviceState = (consumerId, said, apiKey, accessToken) => {
  let apiUrlDomainName
  if (window.location.host === 'works-with-portal-dev.whrcloud.com') {
    apiUrlDomainName = 'works-with-api-dev.whrcloud.com'
  } else if (window.location.host === 'works-with-portal-qa.whrcloud.com') {
    apiUrlDomainName = 'works-with-api-qa.whrcloud.com'
  } else if (window.location.host === 'works-with-portal-stg.whrcloud.com') {
    apiUrlDomainName = 'works-with-api-stg.whrcloud.com'
  } else if (window.location.host === 'works-with-portal.whrcloud.com') {
    apiUrlDomainName = 'works-with-api.whrcloud.com'
  } else {
    apiUrlDomainName = 'works-with-api-stg.whrcloud.com'
  }
  console.log(getLoginRedirectUrl())
  console.log(window.location.host)
  if (!accessToken.includes('Bearer') && !accessToken.includes('bearer')) {
    accessToken = `Bearer ${accessToken}`
  }
  return isAuthenticated() ? axios.get(`https://${apiUrlDomainName}/deviceState/${said}`,
    {
      headers: {
        'x-api-key': apiKey,
        'consumer-id': consumerId,
        language: 'ENG',
        region: 'NAR',
        Authorization: accessToken
      }
    }).then(response => response.data) : false
}
