import React, { useCallback, useState } from 'react'
import { Grid, Header, Popup, Loader, Message, List, Divider, Table, Button, Modal, Form, Icon } from 'semantic-ui-react'
import Chart from 'chart.js'
import { fetchUsage, mapUsageByDate } from 'services/api-catalog'
import { toTitleCase } from 'services/misc'
import { observer, useLocalStore } from 'mobx-react'
import { store } from 'services/state'
import ApisMenu from 'components/ApisMenu'
import PageWithSidebar from 'components/PageWithSidebar'
import _ from 'lodash'
import { apiGatewayClientWithCredentials } from '../services/api'
import * as MessageList from 'components/MessageList'
import * as AccountService from 'services/accounts'

function loadUsage (usagePlan, canvasId) {
  fetchUsage(usagePlan.id)
    .then((result) => {
      const data = mapUsageByDate(result.data, 'used')
      const ctx = document.getElementById(canvasId)

      const oldDataString = JSON.stringify(_.get(usagePlan, 'usage.data', {}))
      const newDataString = JSON.stringify(data)

      if (oldDataString !== newDataString) { usagePlan.usage = { data } }

      const labels = data.map(d => new Date(d[0]).toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }))
      const used = data.map(d => d[1])
      const remaining = data.map(d => d[2])
      const max = Math.max(...used, ...remaining)

      const chartConfig = {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Requests used' + (usagePlan.quota ? ` (per ${toTitleCase(usagePlan.quota.period)})` : ''),
              data: used,
              lineTension: 0,
              backgroundColor: '#00bfff',
              borderColor: '#00bfff',
              pointBackgroundColor: 'transparent',
              pointBorderColor: 'transparent',
              borderWidth: 2,
              pointRadius: 10,
              pointHoverRadius: 10

            },
            {
              label: 'Remaining requests' + (usagePlan.quota ? ` (per ${toTitleCase(usagePlan.quota.period)})` : ''),
              data: remaining,
              lineTension: 0,
              backgroundColor: 'transparent',
              borderColor: 'red',
              pointBackgroundColor: 'transparent',
              pointBorderColor: 'transparent',
              borderWidth: 2,
              pointRadius: 10,
              pointHoverRadius: 10
            }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                suggestedMax: max + (max * 0.02)
              }
            }]
          }
        }
      }

      // eslint-disable-next-line no-new
      new Chart(ctx, chartConfig)
    })
    .catch((error) => {
      console.error(error)
      if (!usagePlan.usage) { usagePlan.usage = { } }

      usagePlan.usage.error = error
    })
}

function getAppsInfo () {
  apiGatewayClientWithCredentials()
    .then(apiGatewayClient => apiGatewayClient.get('/app', {}, {}, {}))
    .then(response => {
      if (response.status === 200) {
        store.appsArray = Object.entries(response.data)
      } else {
        console.log('An error occurred while trying to get or process the apps info.')
      }
    })
}

function refreshPartnerApps () {
  apiGatewayClientWithCredentials()
    .then(apiGatewayClient => apiGatewayClient.get('/userDetails', {}, {}, {}))
    .then(({ data }) => {
      store.userType = data.userType
      if (Object.keys(data.partnerDetails).length > 0) {
        store.partnerApps = data.partnerDetails.appkeys
      }
    })
}

getAppsInfo()
export default observer(function DashboardPage (props) {
  const [createAppModalOpen, setCreateAppModalOpen] = useState(false)
  const [deleteAppModalOpen, setDeleteAppModalOpen] = useState(false)
  const [modifyAppModalOpen, setModifyAppModalOpen] = useState(false)
  const [appId, setAppId] = useState(undefined)
  const [appName, setAppName] = useState(undefined)
  const [callbackUrl, setCallbackUrl] = useState(undefined)
  const createButtonClick = () => {
    setCreateAppModalOpen(true)
  }
  const deleteButtonClick = (appId, appName) => {
    setDeleteAppModalOpen(true)
    setAppId(appId)
    setAppName(appName)
  }
  const modifyButtonClick = (appId, appName, callbackUrl) => {
    setModifyAppModalOpen(true)
    setAppId(appId)
    setAppName(appName)
    setCallbackUrl(callbackUrl)
  }
  return (
    <PageWithSidebar
      sidebarContent={<ApisMenu path={props.match} activateFirst={false} />}
      SidebarPusherProps={{ className: 'swagger-section' }}
    >
      <Grid container>
        <Grid.Row>
          <Grid.Column style={{ paddingTop: '40px' }}>
            <Header size='medium'>Apps</Header>
            <Button.Group floated='right'>
              <Button
                positive
                content='Create App'
                onClick={createButtonClick}
              />
            </Button.Group>
            <CreateAppModal
              open={createAppModalOpen}
              onClose={() => setCreateAppModalOpen(false)}
            />
            <DeleteAppModal
              open={deleteAppModalOpen}
              onClose={() => setDeleteAppModalOpen(false)}
              appId={appId}
              appName={appName}
            />
            <ModifyAppModal
              open={modifyAppModalOpen}
              onClose={() => setModifyAppModalOpen(false)}
              appId={appId}
              appName={appName}
              callbackUrl={callbackUrl}
            />
            <br/><br/>
            {Object.values(store.appsArray).map((member) => {
              const myAppItself = member[1]
              return (
                <div>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell colSpan={2}>{myAppItself.AppName}&nbsp; &nbsp;<Icon link name='edit' size='large' color='blue' onClick={() => modifyButtonClick(member[0], myAppItself.AppName, myAppItself.PortalCallBackUrl)} />&nbsp; &nbsp;<Icon link name='trash' size='large' color='grey' onClick={() => deleteButtonClick(member[0], myAppItself.AppName)} /></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Client App Key</Table.Cell>
                        <Table.Cell>{myAppItself.ClientAppKey}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Key Issued</Table.Cell>
                        <Table.Cell>{myAppItself.CreatedAt}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Key Expires</Table.Cell>
                        <Table.Cell>Never</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Callback URL</Table.Cell>
                        <Table.Cell>{myAppItself.PortalCallBackUrl}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <br/>
                </div>
              )
            })}
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          {store.usagePlans
            .filter(usagePlan => usagePlan.subscribed && usagePlan.apis.length)
            .map((usagePlan, index) => {
              const canvasId = `api-usage-chart-container-${usagePlan.id}` + index
              loadUsage(usagePlan, canvasId)
              return (
                <Grid.Column width={16} widescreen={8} key={usagePlan.id} style={{ marginBottom: '40px' }}>
                  <Title apis={usagePlan.apis} />
                  {usagePlan.throttle && (
                    <Message info>
                      <p>
                        Requests limited to {usagePlan.throttle.rateLimit} per second, and {usagePlan.throttle.burstLimit} in a burst.
                      </p>
                    </Message>
                  )}
                  {!usagePlan.usage ? (
                    <Loader active />
                  ) : (
                    usagePlan.error ? (
                      <Message error content={usagePlan.error.toString()} />
                    ) : null
                  )}
                  <canvas id={canvasId} />
                </Grid.Column>
              )
            })}
        </Grid.Row>
      </Grid>
    </PageWithSidebar>
  )
})

function CreateAppModal ({ open, onClose }) {
  const [messages, sendMessage, clearMessages] = MessageList.useMessages()
  const localStore = useLocalStore(() => ({
    appName: '',
    callbackUrl: ''
  }))
  const onClickCreate = useCallback(async () => {
    console.log('Clicked!!')
    try {
      await AccountService.createPartnerApp(localStore.appName, localStore.callbackUrl)
      sendMessage(dismiss => (
        <CreateAppSuccessMessage appName={localStore.appName} dismiss={dismiss} />
      ))
    } catch (error) {
      sendMessage(dismiss => (
        <CreateAppFailureMessage
          appName={localStore.appName}
          dismiss={dismiss}
          errorMessage={error}
        />
      ))
    } finally {
      getAppsInfo()
      refreshPartnerApps()
    }
  })

  const onClickClose = () => {
    onClose()
    clearMessages()
  }
  return (
    <Modal open={open} onClose={onClose} size={'small'}>
      <Modal.Header>Create App</Modal.Header>
      <Modal.Content>
        <Form>
          <MessageList.MessageList messages={messages} />
          <Form.Field>
            <label>App Name: </label>
            <Form.Input placeholder="App Name..." onChange={(e) => {
              localStore.appName = e.target.value
            }}/>
          </Form.Field>
          <Form.Field>
            <label>Callback Url: </label>
            <Form.Input placeholder="Callback url..." onChange={(e) => {
              localStore.callbackUrl = e.target.value
            }}/>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClickClose}>
          Close
        </Button>
        <Button
          positive
          onClick={onClickCreate}
        >
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function DeleteAppModal ({ open, onClose, appId, appName }) {
  const [messages, sendMessage, clearMessages] = MessageList.useMessages()
  const confirmDelete = useCallback(async () => {
    console.log('Clicked!!')
    try {
      await AccountService.deletePartnerApp(appId)
      onClickClose()
    } catch (error) {
      sendMessage(dismiss => (
        <DeleteAppFailureMessage
          appName={appName}
          dismiss={dismiss}
          errorMessage={error}
        />
      ))
    } finally {
      getAppsInfo()
      refreshPartnerApps()
    }
  })
  const onClickClose = () => {
    onClose()
    clearMessages()
  }
  return (
    <Modal size='small' open={open} onClose={onClose}>
      <Modal.Header>Confirm App deletion</Modal.Header>
      <Modal.Content>
        <MessageList.MessageList messages={messages} />
        <p>
          Are you sure you want to delete this App{' '}
          <strong>{appName}</strong>? This action is irreversible.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClickClose}>Cancel</Button>
        <Button negative onClick={confirmDelete}>
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function ModifyAppModal ({ open, onClose, appId, appName, callbackUrl }) {
  const localStore = useLocalStore(() => ({
    appName: '',
    callbackUrl: ''
  }))
  localStore.appName = appName
  localStore.callbackUrl = callbackUrl
  const [messages, sendMessage, clearMessages] = MessageList.useMessages()
  const onClickUpdate = useCallback(async (event) => {
    console.log('Clicked!!')
    const appName = event.target.appName.value
    const callbackUrl = event.target.callbackUrl.value
    try {
      await AccountService.updatePartnerApp(appName, callbackUrl, appId)
      sendMessage(dismiss => (
        <ModifyAppSuccessMessage appName={appName} dismiss={dismiss} />
      ))
    } catch (error) {
      sendMessage(dismiss => (
        <ModifyAppFailureMessage
          appName={appName}
          dismiss={dismiss}
          errorMessage={error}
        />
      ))
    } finally {
      getAppsInfo()
      refreshPartnerApps()
    }
  })

  const onClickClose = () => {
    onClose()
    clearMessages()
  }
  return (
    <Modal open={open} onClose={onClose} size={'small'}>
      <Modal.Header>Modify App</Modal.Header>
      <Modal.Content>
        <Form onSubmit={(e) => { onClickUpdate(e) }}>
          <MessageList.MessageList messages={messages} />
          <Form.Field>
            <label>App Name: </label>
            <Form.Input name="appName" placeholder="App Name..." onChange={(e) => {
              // setAppNameInput(e.target.value)
              localStore.appName = e.target.value
            }} defaultValue={localStore.appName}/>
          </Form.Field>
          <Form.Field>
            <label>Callback Url: </label>
            <Form.Input name="callbackUrl" placeholder="Callback url..." onChange={(e) => {
              // setCallbackUrlInput(e.target.value)
              localStore.callbackUrl = e.target.value
            }} defaultValue={localStore.callbackUrl}/>
          </Form.Field>
          <Modal.Actions>
            <Button onClick={onClickClose}>
              Close
            </Button>
            <Button
              positive
              type='submit'
            >
              Update
            </Button>
          </Modal.Actions>
        </Form>
      </Modal.Content>
    </Modal>
  )
}

const CreateAppSuccessMessage = React.memo(({ appName, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      App <strong>{appName}</strong> has been created successfully.
    </Message.Content>
  </Message>
))
const CreateAppFailureMessage = React.memo(
  ({ appName, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to create App <strong>{appName}</strong>. Error: {errorMessage.message}
        </p>
      </Message.Content>
    </Message>
  )
)

const ModifyAppSuccessMessage = React.memo(({ appName, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      App <strong>{appName}</strong> has been upddated successfully.
    </Message.Content>
  </Message>
))
const ModifyAppFailureMessage = React.memo(
  ({ appName, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to update the App <strong>{appName}</strong>. Error: {errorMessage.message}
        </p>
      </Message.Content>
    </Message>
  )
)

const DeleteAppFailureMessage = React.memo(
  ({ appName, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to delete App <strong>{appName}</strong>. Error: {errorMessage.message}
        </p>
      </Message.Content>
    </Message>
  )
)

const Title = ({ apis }) => {
  const firstApiName = apis[0].swagger.info.title

  const apiList = (
    <List>
      {apis.reduce((acc, api) => acc.concat(
        <List.Item key={api.id}>{api.swagger.info.title}</List.Item>
      ), [])}
    </List>
  )

  const extraApiCount = apis.length - 1

  return (
    <Header size='medium'>
      Usage for {extraApiCount ? (
        <Popup
          trigger={<button style={{ cursor: 'pointer' }}>{firstApiName} and {extraApiCount} more...</button>}
          content={apiList}
          on={['hover', 'click']}
          position='right center'
        />
      ) : (
        firstApiName
      )}
    </Header>
  )
}
