import { apiGatewayClientWithCredentials as getApiGatewayClient } from 'services/api'

const getAccountsWithFilter = async filter => {
  console.log('About to call getApiGatewayClient')
  const apiGatewayClient = await getApiGatewayClient()
  console.log('About to call getApiGatewayClient.get')
  const response = await apiGatewayClient.get(
    '/admin/accounts',
    undefined,
    undefined,
    { queryParams: { filter } }
  )
  console.log('About to return response.data.accounts')
  return response.data.accounts
}

const url = ([first, ...rest], ...parts) =>
  first + parts.map((p, i) => encodeURIComponent(p) + rest[i]).join('')

const fetch = (method, target, params = {}) =>
  getApiGatewayClient()
    .then(apiGatewayClient => apiGatewayClient[method](target, {}, params, {}))
    .then(response => response.data)
    .catch(error => { throw error && (error.data || error) })

export const fetchRegisteredAccounts = () => getAccountsWithFilter('registered')
export const fetchAdminAccounts = () => getAccountsWithFilter('admin')
export const fetchPendingRequestAccounts = () => getAccountsWithFilter('pendingRequest')
export const fetchPendingInviteAccounts = () => getAccountsWithFilter('pendingInvite')

export const deleteAccountByUserId = userId => fetch('delete', url`/admin/accounts/${userId}`)
// TODO: verify if this is even correct - I suspect it should've posted to
// `/admin/accounts/:userId/denyRequest` instead.
export const deleteInviteByUserId = userId => fetch('delete', url`/admin/accounts/${userId}`)
export const createInviteByEmail = email => fetch('post', '/admin/accounts', { targetEmailAddress: email })
export const resendInviteByEmail = email => fetch('put', '/admin/accounts/resendInvite', { targetEmailAddress: email })
export const promoteAccountByUserId = userId => fetch('put', url`/admin/accounts/${userId}/promoteToAdmin`)
export const approveAccountRequestByUserId = userId => fetch('put', url`/admin/accounts/${userId}/approveRequest`)
export const denyAccountRequestByUserId = userId => fetch('put', url`/admin/accounts/${userId}/denyRequest`)
export const associateAccountByUserId = (userId, username, partnerAndDept) => fetch('put', '/admin/accounts/associate', { userId: userId, username: username, partnerAndDept: partnerAndDept })
export const disassociateAccountByUserId = (userId, username, partnerAndDept, hostName) => fetch('put', '/admin/accounts/disassociate', { userId: userId, username: username, partnerAndDept: partnerAndDept, hostName: hostName })
export const getLinkedAppliances = (username, partnerAndDept, hostName) => fetch('post', '/admin/accounts/linkedAppliances', { username: username, partnerAndDept: partnerAndDept, hostName: hostName })
export const requestVirtualAppliance = (userId, username, virtualApplianceRequestObject, partnerAndDept, requesterEmailId) => fetch('post', '/admin/accounts/requestVirtualAppliance', { userId: userId, username: username, virtualApplianceRequestObject: virtualApplianceRequestObject, partnerAndDept: partnerAndDept, requesterEmailId: requesterEmailId })
export const unclaimVirtualAppliance = (said, username, partnerAndDept, hostName) => fetch('put', '/admin/accounts/unclaimVirtualAppliance', { said: said, username: username, partnerAndDept: partnerAndDept, hostName: hostName })
export const refreshLinkedAppliances = (username, partnerAndDept, hostName) => fetch('post', '/admin/accounts/refreshLinkedAppliances', { username: username, partnerAndDept: partnerAndDept, hostName: hostName })
export const registerPartnerAccount = (partnerEmail, partnerName, partnerCode, partnerDivisionName, usagePlanId) => fetch('post', '/admin/register/partner', { email: partnerEmail, partnerName: partnerName, partnerCode: partnerCode, partnerDivisionName: partnerDivisionName, usagePlanId: usagePlanId })
export const createPartnerApp = (appName, callbackUrl) => fetch('post', '/app', { appName: appName, portalCallBackUrl: callbackUrl })
export const updatePartnerApp = (appName, callbackUrl, appId) => fetch('put', url`/app/${appId}`, { appName: appName, portalCallBackUrl: callbackUrl })
export const deletePartnerApp = appId => fetch('delete', url`/app/${appId}`)
export const fetchPartnerApp = appId => fetch('get', url`/app/${appId}`)
