// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Menu, Image, Dropdown } from 'semantic-ui-react'

import {
  isAdmin,
  isAuthenticated,
  isRegistered,
  logout,
  getLoginRedirectUrl
} from 'services/self'

import { cognitoDomain, cognitoClientId } from '../services/api'
import { observer } from 'mobx-react'
import { fragments } from 'services/get-fragments'
import MenuLink from 'components/MenuLink'
import { store } from 'services/state'
import '../styles/navbar.css'

export function getCognitoUrl (type) {
  const redirectUri = getLoginRedirectUrl()
  return `${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`
}

export const NavBar = observer(
  class NavBar extends React.Component {
    render () {
      const email = store.user && store.user.email
      return (
        <Menu
          attached
          borderless
          className='navbar'
          stackable
          style={{ flex: '0 0 auto' }}
        >
          <MenuLink to='/'>
            <Image className='www-logo-img' src='/custom-content/images/www-logo.png' />
          </MenuLink>
          {
            isAuthenticated()
              ? <>

              </>
              : ''
          }

          <Menu.Menu position='right'>
            <MenuLink position='right' to='/getting-started'>{fragments.GettingStarted.title}</MenuLink>
            {
              isAuthenticated()
                ? <>
                  <MenuLink to='/apis'>{fragments.APIs.title}</MenuLink>
                  <MenuLink to='/deviceState'>Device State</MenuLink>
                  {isAdmin() && <MenuLink to='/admin/apis'>Admin Panel</MenuLink>}
                  {isRegistered() && <MenuLink to='/dashboard'>My Dashboard</MenuLink>}
                  {
                    <Dropdown
                      icon='user circle'
                      selection
                      text={email}
                      labeled
                      button
                      className='icon'
                      style={{
                        margin: '0.938rem',
                        width: 'auto',
                        fontSize: '0.75rem',
                        padding: '0.75rem 0.75rem',
                        fontWeight: '700'
                      }}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={logout}>
                          <p>Sign Out</p>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </>
                : <>
                  <MenuLink style={{ marginRight: '1rem' }} to={getCognitoUrl('login')}>Login</MenuLink>
                  {/* Including commented-out code in case the yellow Register button is requested later:
                  <div className='div-for-register-button'>
                    <PillButton
                      label='Register'
                      not-large
                      positive
                      primary
                    />
                  </div> */}
                </>
            }
          </Menu.Menu>
        </Menu>
      )
    }
  }
)

export default NavBar
