import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Container, Form, Modal as SemanticModal } from 'semantic-ui-react'
import WorksWithWhirlpoolLogo from 'components/WorksWithWhirlpoolLogo'
import PillButton from 'components/PillButton'
import Card, { cardGroupLayout } from 'components/Card'
import Modal from 'components/Modal'
import { store } from 'services/state'
import FormTextInput from 'components/FormTextInput'

const spacerStyle = {
  flex: '1'
}

const ExampleModalContentForm = () => {
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()

  const handleUsernameChange = (event) => {
    setUsername(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleSubmit = () => {
    // Here you would insert code to handle the form submission, validate the fields statically, and transform the data
    // if necessary
    const userInfoString = `Received [username: ${username}, password length: ${password.length}]`
    window.alert(userInfoString)
    // If all is good, proceed to closing the modal
    store.activeModalRef.current.handleClose()
  }

  return (
    <SemanticModal.Content>
      <Form>
        <FormTextInput
          id='username'
          label='Username'
          onChange={(event) => handleUsernameChange(event)}
          placeholder='bob1234'
          required
          type='text'
        />
        <FormTextInput
          id='password'
          label='Password'
          onChange={(event) => handlePasswordChange(event)}
          required
          type='password'
        />
        <SemanticModal.Actions>
          <PillButton primary label='Cancel' onClick={() => {
            store.activeModalRef.current.handleClose()
          }} />
          <PillButton primary type='submit' label='Submit' onClick={handleSubmit} />
        </SemanticModal.Actions>
      </Form>
    </SemanticModal.Content>
  )
}

export default observer(() => (
  <section
    id='components-dev-page'
    style={{
      backgroundColor: 'var(--lighter-grey)',
      height: '100vh'
    }}
  >
    <Container style={{ padding: '40px' }}>
      <h1>Components</h1>
      <h3>WorksWithWhirlpoolLogo</h3>
      <span>Normal Size:</span>
      <WorksWithWhirlpoolLogo />
      <span>Height of 2rem:</span>
      <WorksWithWhirlpoolLogo style={{ height: '2rem' }} />
      <h3>Buttons</h3>
      <PillButton primary label='PillButton' />
      <PillButton primary large label='PillButton (Large)' />
      <h3>Cards</h3>
      <div
        id='example-group-of-cards'
        style={{
          ...cardGroupLayout,
          background: 'white',
          padding: '1rem',
          borderRadius: '.5rem'
        }}
      >
        <Card
          icon={{
            alt: 'Plug',
            src: '/custom-content/images/plug.svg'
          }}
          content='Example text is a great example of your text, in example form'
        />
        <Card
          icon={{
            alt: 'Document',
            src: '/custom-content/images/document.svg'
          }}
          content='More example text is an even greater example of your text, in an additional example form'
        />
        <div style={spacerStyle}></div>
      </div>
      <h3>Modals</h3>
      <Modal
        title='Modal Title'
        trigger={<PillButton primary label='Open Default Modal' />}
      >
        <ExampleModalContentForm />
      </Modal>
      <Modal
        style={{ maxWidth: '383px' }}
        title='I am smol'
        trigger={<PillButton primary label='Open Smaller Modal' />}
      >
        <SemanticModal.Actions>
          <PillButton primary label='Close Me!' onClick={() => {
            store.activeModalRef.current.handleClose()
          }} />
        </SemanticModal.Actions>
      </Modal>
    </Container>
  </section>
))
