import React from 'react'
import PropTypes from 'prop-types'

const wwwLogoSvgPath = '/custom-content/images/www-logo.svg'

const WorksWithWhirlpoolLogo = ({ className, style }) => {
  return (
    <img
      alt='Works With Whirlpool Logo'
      className={className !== undefined ? className + ' www-logo' : 'www-logo'}
      src={wwwLogoSvgPath}
      style={style}
    />
  )
}

WorksWithWhirlpoolLogo.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
}

export default WorksWithWhirlpoolLogo
