// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { observer } from 'mobx-react'
import { fragments } from 'services/get-fragments'
import { Container } from 'semantic-ui-react'
import '../styles/getting-started.css'

export default observer(() => (
  <section
    id='getting-started-section'>
    <Container style={{ padding: '40px' }}>
      <fragments.GettingStarted.jsx />
    </Container>
  </section>
))
