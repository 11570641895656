// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import * as queryString from 'query-string'

// content-fragments
import { loadFragments } from 'services/get-fragments'

// semantic-ui
import 'semantic-ui-css/semantic.css'

// pages
import Home from 'pages/Home'
import GettingStarted from 'pages/GettingStarted'
import Dashboard from 'pages/Dashboard'
import Apis from 'pages/Apis'
import { Admin } from 'pages/Admin'
import LoginModule from 'pages/LoginModule'

// components
import AlertPopup from 'components/AlertPopup'
import GlobalModal from 'components/LegacyModal'
import NavBar from 'components/NavBar'
import Feedback from './components/Feedback'
import ApiSearch from './components/ApiSearch'

import { isAdmin, isRegistered, init, login, logout, isDevelopmentEnvironment } from 'services/self'
import './index.css'
import './styles/theme.css'
import DeviceState from 'components/DeviceState'
import Components from 'pages/Dev/Components'
import { PortalFooter } from './components/PortalFooter'
import { Provider as AlertProvider, positions, transitions } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

window.Buffer = window.Buffer || require('buffer').Buffer

loadFragments()

const feedbackEnabled = window.config?.feedbackEnabled

export const RegisteredRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      isRegistered()
        ? <Component {...props} />
        : <Redirect to='/' />
    )}
  />
)

export const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest} render={props => (
      isAdmin()
        ? <Component {...props} />
        : <Redirect to='/' />
    )}
  />
)

export const DevelopmentRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest} render={props => (
      isDevelopmentEnvironment()
        ? <Component {...props} />
        : <Redirect to='/' />
    )}
  />
)

// To shut up a dev warning
const HomeWrap = props => <Home {...props} />
const GettingStartedWrap = props => <GettingStarted {...props} />
const DashboardWrap = props => <Dashboard {...props} />
const ComponentsPageWrap = props => <Components {...props} />
const LoginModuleWrap = props => <LoginModule {...props} />

const options = {
  position: positions.MIDDLE,
  timeout: 0, // Alert doesn't go away based on how much time has passed
  offset: '20px', // How much to move the alert down from its position
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 9999 // This allows the alert to show up in front of even a modal
  }
}

class App extends React.Component {
  constructor () {
    super()
    init()

    // We are using an S3 redirect rule to prefix the url path with #!
    // This then converts it back to a URL path for React routing
    if (window.location.hash && window.location.hash[1] === '!') {
      const hashRoute = window.location.hash.substring(2)
      window.history.pushState({}, 'home page', hashRoute)
    }
  }

  render () {
    return (
      <AlertProvider template={AlertTemplate} {...options}>
        <BrowserRouter>

          <Switch>
            <Route exact path='/login-module' component={LoginModuleWrap}/>
            <Route path='/' component={DevPortalSite}/>
          </Switch>

        </BrowserRouter>
      </AlertProvider>
    )
  }
}

class DevPortalSite extends React.Component {
  render () {
    return (
      <div id='portal-wrapper'>
        <NavBar />

        <GlobalModal/>

        <div id='main-area-wrapper'>
          <Switch>
            <Route exact path='/' component={HomeWrap}/>

            <Route
              exact
              path='/index.html'
              component={() => {
                const { action } = queryString.parse(window.location.search)
                if (action === 'login') {
                  login()
                } else if (action === 'logout') {
                  logout()
                }
                return <Redirect to='/'/>
              }}
            />

            <Route path='/getting-started-bkp' component={GettingStartedWrap}/>

            <Route path='/getting-started' render={() => {
              window.open('/www-docs/intro/index.html', '_blank')
              return <Redirect to='/' />
            }}/>

            <Route path='/www-docs' render={() => {
              location.href = '/www-docs/intro/index.html'
            }}/>

            <RegisteredRoute path='/dashboard' component={DashboardWrap}/>

            <AdminRoute path='/admin' component={Admin}/>

            <Route exact path='/apis' component={Apis}/>

            <Route exact path='/deviceState' component={DeviceState}/>

            <DevelopmentRoute exact path='/dev-components' component={ComponentsPageWrap}/>

            <Route exact path='/apis/search' component={ApiSearch}/>

            <Route exact path='/apis/:apiId' component={Apis}/>

            <Route path='/apis/:apiId/:stage' component={Apis}/>

            <Route path='/login' render={() => {
              login()
              return <Redirect to='/'/>
            }}/>

            <Route path='/logout' render={() => {
              logout()
              return <Redirect to='/'/>
            }}/>

            <Route component={() => <h2>Page not found</h2>}/>
          </Switch>
        </div>

        {feedbackEnabled && <Feedback/>}

        <AlertPopup/>

        <PortalFooter/>
      </div>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
