import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import '../styles/components/pill-button.css'

const PillButton = ({ label, large, onClick, ...props }) => {
  return (
    <Button
      className={!large ? 'pill-button' : 'large pill-button'}
      onClick={onClick}
      {...props}
    >
      {label}
    </Button>
  )
}

PillButton.propTypes = {
  label: PropTypes.string,
  large: PropTypes.bool,
  onClick: PropTypes.func
}

PillButton.defaultValues = {
  label: '',
  large: false,
  onClick: () => {}
}

export default PillButton
