import React from 'react'
import PropTypes from 'prop-types'
import '../styles/components/card.css'

const Card = ({ content, icon }) => {
  return (
    <div className='www-card'>
      <div className='www-card--icon-section'>
        <img alt={icon.alt} src={icon.src} />
      </div>
      <div className='www-card--content-section'>
        <p>{content}</p>
      </div>
    </div>
  )
}

Card.propTypes = {
  icon: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired,
  content: PropTypes.string.isRequired
}

export default Card

export const cardGroupLayout = {
  display: 'flex',
  alignItems: 'stretch',
  columnGap: '1.25rem'
}
