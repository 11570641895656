// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// semantic-ui
import { Header, Image, Container } from 'semantic-ui-react'

// markdown for external docs description
// import Markdown from 'react-markdown/with-html'

// services

import { GetSdkButton } from 'components/GetSdk'

// state
import { Observer } from 'mobx-react'
import { store } from 'services/state.js'

// Create the plugin that provides our layout component
export const SwaggerLayoutPlugin = () => ({ components: { InfoContainer: InfoReplacement } })

// replaces the InfoContainer component
// https://github.com/swagger-api/swagger-ui/blob/dd3afdc45656bda2a64ae6a7f9bdad006ea98149/src/core/components/layouts/base.jsx

// Note: this is called not as a component, but as a function within a class component. Do
// *not* make this a component, and do *not* use hooks or anything similar in it.
function InfoReplacement ({ specSelectors }) {
  let endpoint
  if (specSelectors.hasHost()) {
    endpoint = `https://${specSelectors.host()}${specSelectors.basePath() || ''}`
  } else {
    const servers = specSelectors.servers()
    if (servers && servers.size) endpoint = servers.getIn([0, 'url'])
  }

  const info = specSelectors.info()
  const version = specSelectors.version()
  const externalDocs = specSelectors.externalDocs()
  const apiTitle = info.get('title')
  const apiDescription = info.get('description')
  const docsDescription = externalDocs.get('description')
  const docsUrl = externalDocs.get('url')

  return <Observer>
    {/*
      If no API is loaded, let's just swallow the state and move on. (Swagger UI doesn't offer any
      way to clean up after itself.)
    */}
    {() => store.api == null ? null : <Container fluid textAlign='left' className='fixfloat' style={{ padding: '20px 0px' }}>
      <div style={{ display: 'flex' }}>
        <div>
          <div style={{ paddingBottom: '1em' }}>
            <Header as='h1'>
              <Image size='small' src={store.api.logo} />
              {apiTitle}
            </Header>
          </div>
          <div style={{ display: 'flex', paddingBottom: '1em' }}>
            <div>
              {store.api.apiStage == null ? <p style={{ paddingLeft: 0, textAlignLast: 'left' }}><b>Version:</b> {version}</p> : null}
              {endpoint ? <p style={{ paddingLeft: 0, textAlignLast: 'left' }}><b>Endpoint:</b> {endpoint}</p> : null}
              {apiDescription ? <p style={{ paddingLeft: 0, textAlignLast: 'left' }}>{apiDescription}</p> : null}
              {/* <p>{store.api.usagePlan.name}</p> */}
            </div>
          </div>
          {externalDocs ? (
            <div style={{ paddingBottom: '1em' }}>
              <a href={docsUrl}>{docsDescription}</a>
            </div>
          ) : null}
          {store.api.sdkGeneration && <GetSdkButton />}
        </div>
      </div>
    </Container>}
  </Observer>
}

export default SwaggerLayoutPlugin
