import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal as SemanticModal } from 'semantic-ui-react'
import '../styles/components/modal.css'
import { observer } from 'mobx-react'
import store from 'services/state'

const Modal = observer(({
  children,
  style,
  title,
  trigger
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const modalRef = useRef(null)
  const open = () => {
    setIsOpen(true)
    store.activeModalRef = { ...modalRef, title }
  }
  const close = () => {
    setIsOpen(false)
    store.activeModalRef = { current: {} }
  }
  return (
    <SemanticModal
      onClose={close}
      onOpen={open}
      open={isOpen}
      ref={modalRef}
      style={style}
      trigger={trigger}
    >
      <SemanticModal.Header className='modal--header'>
        <h1>
          {title}
        </h1>
        <button
          className='modal--close-icon-button'
          onClick={close}
        >
          <img alt='Close Button Icon' src='custom-content/images/close-icon.svg' />
        </button>
      </SemanticModal.Header>
      {children}
    </SemanticModal>
  )
})

Modal.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  title: PropTypes.string,
  trigger: PropTypes.node.isRequired
}

export default Modal
