import React, { useCallback, useEffect, useState } from 'react'
import { Button, Container, Header, Message, Modal, Select, Checkbox, Popup, Confirm } from 'semantic-ui-react'

import * as MessageList from 'components/MessageList'
import * as AccountService from 'services/accounts'
import * as AccountsTable from 'components/Admin/Accounts/AccountsTableRegisteredAccounts'
import * as AccountsTableColumns from 'components/Admin/Accounts/AccountsTableColumns'
import { store } from 'services/state'
import _ from 'lodash'
import '../../../styles/login-module.css'
import '../../../styles/components/modal.css'
import '../../../styles/accounts.css'
import { useAlert } from 'react-alert'
import styles from '../../../components/Admin/Accounts/AccountsTable.module.css'

const RegisteredAccounts = () => {
  const [accounts, setAccounts] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedAccount, setSelectedAccount] = useState(undefined)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [promoteModalOpen, setPromoteModalOpen] = useState(false)
  const [associateModalOpen, setAssociateModalOpen] = useState(false)
  const [disassociateModalOpen, setDisassociateModalOpen] = useState(false)
  const [requestVirtualApplianceModalOpen, setRequestVirtualApplianceModalOpen] = useState(false)
  const [confirmUnclaimModalOpen, setConfirmUnclaimModalOpen] = useState(false)
  const [linkedAppliancesContent, setLinkedAppliancesContent] = useState(undefined)
  const [selectedSaidAndDescriptionArray, setSelectedSaidAndDescriptionArray] = useState(['', ''])
  const [userApplianceCache] = useState(new Map())
  const [messages, sendMessage] = MessageList.useMessages()
  const [applianceListLoading, setApplianceListLoading] = useState(false)
  const refreshAccounts = async () => {
    const [all, admins] = await Promise.all([
      AccountService.fetchRegisteredAccounts(),
      AccountService.fetchAdminAccounts()
    ])

    const allMap = {}

    all.forEach(user => { allMap[user.UserId] = user })
    admins?.forEach(admin => {
      admin.IsAdmin = true
      if (admin.EmailAddress.toUpperCase() === store.user.email.toUpperCase()) {
        admin.EmailAddress += ' (you)'
      }
      allMap[admin.UserId] = admin
    })

    return setAccounts(Object.values(allMap))
  }

  const isYou = (user) => {
    return (_.get(store, 'user.email') + ' (you)').toUpperCase() === user.EmailAddress.toUpperCase()
  }

  const isUserStatusAssociated = (user) => {
    return user.Associated === true
  }

  // Initial load
  useEffect(() => {
    refreshAccounts().finally(() => setLoading(false))
  }, [])

  // This is what is triggered when you click a row in the table.
  const onSelectAccount = useCallback(async account => {
    await setSelectedAccount(account)
    // selectedAccount does not update immediately, so we continue to use account
    if (account && account.Associated === true) {
      if (userApplianceCache.has(account.EmailAddress)) {
        setLinkedAppliancesContent(displayOfAssignedAppliances(userApplianceCache.get(account.EmailAddress)))
      } else {
        getLinkedAppliancesFromDatabaseAndDisplay(account)
      }
    } else {
      setLinkedAppliancesContent(<div style={{ textIndent: '3.8rem' }}>The user is not associated</div>)
    }
  }, [
    setSelectedAccount
  ])

  // This is what is triggered when you click on Sync button on Linked appliances list.
  const onRefreshLinkedAppliances = useCallback(async account => {
    console.log('Inside RegisteredAccounts-onRefreshLinkedAppliances') // TODO: Remove later
    await setSelectedAccount(account)

    // selectedAccount does not update immediately, so we continue to use account
    if (account.Associated === true) {
      refreshLinkedAppliancesFromDatabaseAndDisplay(account)
    } else {
      setLinkedAppliancesContent(<div style={{ textIndent: '3.8rem' }}>The user is not associated</div>)
    }
  }, [
    setSelectedAccount
  ])

  const onConfirmDelete = useCallback(async () => {
    setLoading(true)
    setDeleteModalOpen(false)
    try {
      await AccountService.deleteAccountByUserId(selectedAccount.UserId)
      sendMessage(dismiss => (
        <DeleteSuccessMessage account={selectedAccount} dismiss={dismiss} />
      ))
      await refreshAccounts()
    } catch (error) {
      sendMessage(dismiss => (
        <DeleteFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error.message}
        />
      ))
    } finally {
      setLoading(false)
    }
  }, [sendMessage, selectedAccount])

  const onConfirmPromote = useCallback(async () => {
    setLoading(true)
    setPromoteModalOpen(false)
    try {
      await AccountService.promoteAccountByUserId(selectedAccount.UserId)
      sendMessage(dismiss => (
        <PromoteSuccessMessage account={selectedAccount} dismiss={dismiss} />
      ))
      await refreshAccounts()
    } catch (error) {
      sendMessage(dismiss => (
        <PromoteFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error.message}
        />
      ))
    } finally {
      setLoading(false)
    }
  }, [sendMessage, selectedAccount])

  const onConfirmAssociate = useCallback(async () => {
    setLoading(true)
    setAssociateModalOpen(false)
    try {
      const partnerAndDept = store.partnerCode + ':' + store.partnerDivisionName
      await AccountService.associateAccountByUserId(selectedAccount.UserId, getCleanEmailAddress(selectedAccount.EmailAddress), partnerAndDept)
      sendMessage(dismiss => (
        <AssociateSuccessMessage account={selectedAccount} dismiss={dismiss} />
      ))
      await refreshAccounts()
    } catch (error) {
      sendMessage(dismiss => (
        <AssociateFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error.message}
        />
      ))
    } finally {
      setLoading(false)
    }
  }, [sendMessage, selectedAccount])

  const onConfirmDisassociate = useCallback(async () => {
    setLoading(true)
    setDisassociateModalOpen(false)
    try {
      const partnerAndDept = store.partnerCode + ':' + store.partnerDivisionName
      const hostName = window.location.hostname
      await AccountService.disassociateAccountByUserId(selectedAccount.UserId, getCleanEmailAddress(selectedAccount.EmailAddress), partnerAndDept, hostName)
      sendMessage(dismiss => (
        <DisassociateSuccessMessage account={selectedAccount} dismiss={dismiss} />
      ))
      userApplianceCache.delete(selectedAccount.EmailAddress) // Update the user appliance cache when disassociating user
      await refreshAccounts()
    } catch (error) {
      sendMessage(dismiss => (
        <DisassociateFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error}
        />
      ))
    } finally {
      setLoading(false)
    }
  }, [sendMessage, selectedAccount])

  const onConfirmRequestVirtualAppliance = useCallback(async (virtualApplianceReqObject) => {
    // Need to implement logic for Request Virtual Appliance
    setLoading(true)
    setRequestVirtualApplianceModalOpen(false)
    try {
      const requesterEmailId = store.user.email
      const partnerAndDept = selectedAccount.PartnerCode + ':' + selectedAccount.PartnerDivisionName
      await AccountService.requestVirtualAppliance(selectedAccount.UserId, selectedAccount.EmailAddress, virtualApplianceReqObject, partnerAndDept, requesterEmailId)
      sendMessage(dismiss => (
        <RequestVirtualApplianceSuccessMessage account={selectedAccount} dismiss={dismiss} />
      ))
      await refreshAccounts()
    } catch (error) {
      sendMessage(dismiss => (
        <RequestVirtualApplianceFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error}
        />
      ))
    } finally {
      setLoading(false)
    }
  }, [sendMessage, selectedAccount])

  // Partner admins have ' (you)' appended to their email so we need to remove it if relevant
  function getCleanEmailAddress (emailAddress) {
    let cleanEmailAddress = emailAddress
    if (emailAddress.endsWith(' (you)')) {
      cleanEmailAddress = emailAddress.substring(0, emailAddress.length - 6)
    }
    return cleanEmailAddress
  }
  const alert = useAlert()

  const unclaimAppliance = (said) => {
    const partnerAndDept = selectedAccount.PartnerCode + ':' + selectedAccount.PartnerDivisionName
    const hostName = window.location.hostname
    AccountService.unclaimVirtualAppliance(said, getCleanEmailAddress(selectedAccount.EmailAddress), partnerAndDept, hostName)
      .then(saidToDescriptionMap => {
        handleArrayOfAssociatedAppliancesFromDatabase(saidToDescriptionMap, selectedAccount.EmailAddress)
      }).catch((error) => {
        handleSituationWhereNoAssociatedAppliancesInDatabaseOrOtherError(error, selectedAccount.EmailAddress)
      })
  }

  const getLinkedAppliancesFromDatabaseAndDisplay = (account) => {
    const partnerAndDept = account.PartnerCode + ':' + account.PartnerDivisionName
    const cleanEmailAddress = getCleanEmailAddress(account.EmailAddress)
    const hostName = window.location.hostname
    setApplianceListLoading(true)
    console.log('About to get linked appliances for email and partnerAndDept: ' + cleanEmailAddress + ' ' + partnerAndDept)
    AccountService.getLinkedAppliances(cleanEmailAddress, partnerAndDept, hostName).then(saidToDescriptionMap => {
      handleArrayOfAssociatedAppliancesFromDatabase(saidToDescriptionMap, account.EmailAddress)
      setApplianceListLoading(false)
    }).catch((error) => {
      handleSituationWhereNoAssociatedAppliancesInDatabaseOrOtherError(error, account.EmailAddress)
      setApplianceListLoading(false)
    })
  }

  const refreshLinkedAppliancesFromDatabaseAndDisplay = (account) => {
    const partnerAndDept = account.PartnerCode + ':' + account.PartnerDivisionName
    const cleanEmailAddress = getCleanEmailAddress(account.EmailAddress)
    const hostName = window.location.hostname
    setApplianceListLoading(true)
    console.log('Refreshing linked appliances for email and partnerAndDept: ' + cleanEmailAddress + ' ' + partnerAndDept)
    AccountService.refreshLinkedAppliances(cleanEmailAddress, partnerAndDept, hostName).then(saidToDescriptionMap => {
      handleArrayOfAssociatedAppliancesFromDatabase(saidToDescriptionMap, account.EmailAddress)
      setApplianceListLoading(false)
    }).catch((error) => {
      handleSituationWhereNoAssociatedAppliancesInDatabaseOrOtherError(error, account.EmailAddress)
      setApplianceListLoading(false)
    })
  }

  function handleArrayOfAssociatedAppliancesFromDatabase (saidToDescriptionMap, emailAddress) {
    const arrayWithSaidAndDescription = Object.entries(JSON.parse(saidToDescriptionMap))

    if (arrayWithSaidAndDescription.length > 0) { // Associated appliances were found in the database
      setLinkedAppliancesContent(displayOfAssignedAppliances(arrayWithSaidAndDescription))
      userApplianceCache.set(emailAddress, arrayWithSaidAndDescription)
    } else {
      // This else block is not expected to ever occur, because if no appliances are found, we will go to the catch block.
      userApplianceCache.delete(emailAddress)
      setLinkedAppliancesContent(<div style={{ textIndent: '3.8rem' }}>List of appliances is empty.</div>)
    }
  }

  function handleSituationWhereNoAssociatedAppliancesInDatabaseOrOtherError (error, emailAddress) {
    console.log('No assigned appliances were found for this user or some other error occurred.')
    console.log(error)
    if (error.message === 'None') {
      userApplianceCache.delete(emailAddress)
    }
    setLinkedAppliancesContent(<div style={{ textIndent: '3.8rem' }}>{error.message}</div>)
  }

  const showUnclaimConfirmationModal = (saidAndDescriptionArray) => {
    setSelectedSaidAndDescriptionArray(saidAndDescriptionArray)
    setConfirmUnclaimModalOpen(true)
  }

  const displayOfAssignedAppliances = (arrayOfSaidAndDescriptionArrays) => {
    return (
      <div>
        {
          Object.values(arrayOfSaidAndDescriptionArrays).map(saidAndDescriptionArray => {
            return (
              <div className={styles.appliancesIndent}>
                <li>
                  {saidAndDescriptionArray[1]} ({saidAndDescriptionArray[0]})
                  <Button
                    onClick={() => {
                      showUnclaimConfirmationModal(saidAndDescriptionArray)
                    }}
                    style={{ background: 'transparent', border: 'none', marginLeft: '10px', padding: '0px' }}
                  >
                    <span className="glyphicon glyphicon-trash"></span>
                  </Button>
                </li>
              </div>
            )
          })
        }
      </div>
    )
  }

  const handleConfirm = (said) => {
    unclaimAppliance(said)
    setConfirmUnclaimModalOpen(false)
  }

  const handleCancel = () => {
    setConfirmUnclaimModalOpen(false)
  }

  return (
    <Container fluid style={{ padding: '2em' }}>
      <Header as='h1'>Registered accounts</Header>
      <MessageList.MessageList messages={messages} />
      <AccountsTable.AccountsTable
        accounts={accounts}
        columns={[
          AccountsTableColumns.EmailAddress,
          AccountsTableColumns.IsAdmin,
          AccountsTableColumns.DateRegistered,
          AccountsTableColumns.RegistrationMethod,
          AccountsTableColumns.ApiKeyId,
          AccountsTableColumns.Associated
        ]}
        loading={loading}
        selectedAccount={selectedAccount}
        onSelectAccount={onSelectAccount}
        confirmUnclaimModalOpen={confirmUnclaimModalOpen}
        setConfirmUnclaimModalOpen={setConfirmUnclaimModalOpen}
        linkedAppliancesContent={linkedAppliancesContent}
        onRefreshLinkedAppliances={onRefreshLinkedAppliances}
        applianceListLoading={applianceListLoading}
      >
        <TableActions
          canDelete={!loading && selectedAccount && !isYou(selectedAccount)}
          onClickDelete={() => setDeleteModalOpen(true)}
          canPromote={!loading && selectedAccount}
          onClickPromote={() => setPromoteModalOpen(true)}
          isAdmin={selectedAccount && selectedAccount.IsAdmin}
          canAssociate={!loading && selectedAccount && !isUserStatusAssociated(selectedAccount)}
          onClickAssociate={() => setAssociateModalOpen(true)}
          canDisassociate={!loading && selectedAccount && isUserStatusAssociated(selectedAccount)}
          onClickDisassociate={() => setDisassociateModalOpen(true)}
          canRequestVirualAppliance={!loading && selectedAccount && isUserStatusAssociated(selectedAccount)}
          onClickRequestVirtualAppliance={() => setRequestVirtualApplianceModalOpen(true)}
        />
      </AccountsTable.AccountsTable>
      <DeleteAccountModal
        account={selectedAccount}
        onConfirm={onConfirmDelete}
        open={deleteModalOpen}
        isAdmin={selectedAccount && selectedAccount.IsAdmin}
        onClose={() => setDeleteModalOpen(false)}
      />
      <PromoteAccountModal
        account={selectedAccount}
        onConfirm={onConfirmPromote}
        open={promoteModalOpen}
        onClose={() => setPromoteModalOpen(false)}
      />
      <AssociateModal
        account={selectedAccount}
        onConfirm={onConfirmAssociate}
        open={associateModalOpen}
        onClose={() => setAssociateModalOpen(false)}
      />
      <DisassociateModal
        account={selectedAccount}
        onConfirm={onConfirmDisassociate}
        open={disassociateModalOpen}
        onClose={() => setDisassociateModalOpen(false)}
      />
      <RequestVirtualApplianceModal
        alert={alert}
        account={selectedAccount}
        onConfirm={onConfirmRequestVirtualAppliance}
        open={requestVirtualApplianceModalOpen}
        onClose={() => setRequestVirtualApplianceModalOpen(false)}
      />
      <Confirm
        open={confirmUnclaimModalOpen}
        content={`Are you sure you want to unclaim ${selectedSaidAndDescriptionArray[1]} (${selectedSaidAndDescriptionArray[0]})?`}
        onCancel={handleCancel}
        onConfirm={() => handleConfirm(selectedSaidAndDescriptionArray[0])}
      />
    </Container>
  )
}
export default RegisteredAccounts

const TableActions = React.memo(
  ({
    canDelete, onClickDelete, canPromote, onClickPromote, isAdmin,
    canAssociate, onClickAssociate, canDisassociate, onClickDisassociate, canRequestVirualAppliance, onClickRequestVirtualAppliance
  }) => (
    <Button.Group>
      <Popup trigger={<Button
        content='Associate'
        disabled={!canAssociate}
        onClick={onClickAssociate}
      />} content='Assiciate tooltip text will come here' hideOnScroll />
      <Popup trigger={<Button
        content='Disassociate'
        disabled={!canDisassociate}
        onClick={onClickDisassociate}
      />} content='Disassociate tooltip text will come here' hideOnScroll />
      <Popup trigger={<Button
        content='Request Virtual Appliance'
        disabled={!canRequestVirualAppliance}
        onClick={onClickRequestVirtualAppliance}
      />} content='Request virtual Appliance tooltip text will come here' hideOnScroll />
      <Button content='Delete' disabled={!canDelete} onClick={onClickDelete} />
      <Button
        content='Promote to Admin'
        disabled={!canPromote || isAdmin}
        onClick={onClickPromote}
      />
    </Button.Group>
  )
)

const DeleteAccountModal = React.memo(
  ({ account, onConfirm, open, onClose, isAdmin }) =>
    account && (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>Delete account</Modal.Header>
        <Modal.Content>
          {isAdmin && (
            <Message negative>
              <Message.Header>Danger! This is an admin account.</Message.Header>
              <p><strong>Deleting an admin account could cause temporary loss of access and temporary inability to configure the developer portal.</strong></p>
            </Message>
          )}
          <p>
            Are you sure you want to delete the account <strong>{account.EmailAddress}</strong>, and de-activate the             associated API key? This action is irreversible.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button negative onClick={onConfirm}>
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
    )
)

const PromoteAccountModal = React.memo(
  ({ account, onConfirm, open, onClose }) =>
    account && (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>Confirm promotion</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to promote the account{' '}
            <strong>{account.EmailAddress}</strong> to Admin? This will allow
            the account to perform any Admin actions, including deleting and
            promoting other accounts.
          </p>
          <p>
            Only the owner of the Developer Portal can demote the account,
            through the Cognito console.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button negative onClick={onConfirm}>
            Promote
          </Button>
        </Modal.Actions>
      </Modal>
    )
)

const AssociateModal = React.memo(
  ({ account, onConfirm, open, onClose }) =>
    account && (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>Mark Developer as Associated with WCloud Account</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to associate the account{' '}
            <strong>{account.EmailAddress}</strong>? This will mark
            the developer in DevPortalCustomers as associated.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button negative onClick={onConfirm}>
            Associate
          </Button>
        </Modal.Actions>
      </Modal>
    )
)

const DisassociateModal = React.memo(
  ({ account, onConfirm, open, onClose }) =>
    account && (
      <Modal size='small' open={open} onClose={onClose}>
        <Modal.Header>Mark Developer as Disssociated with WCloud Account</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to disassociate the account{' '}
            <strong>{account.EmailAddress}</strong>? This will mark
            the developer in DevPortalCustomers as disassociated.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button negative onClick={onConfirm}>
            Disassociate
          </Button>
        </Modal.Actions>
      </Modal>
    )
)

const RequestVirtualApplianceModal = ({ alert, account, onConfirm, open, onClose }) => {
  const [brand, setBrand] = useState(undefined)
  const [category, setCategory] = useState(undefined)
  const applianceBrandCategoryObject = {
    WHIRLPOOL: {
      WASHER: ['Vmax Washer whirlpool', 'Janus Washer whirlpool'],
      DRYER: ['Vmax Dryer whirlpool', 'Janus dryer Whirlpool'],
      COOKING: ['Minerva Oven whirlpool']
    },
    MAYTAG: {
      WASHER: ['Janus Washer maytag', 'Vmax washer maytag'],
      DRYER: ['Janus Dryer maytag', 'Vmax Dryer Maytag'],
      COOKING: []
    },
    KITCHENAID: {
      WASHER: [],
      DRYER: [],
      COOKING: ['KADoubleDuelFuel_V1']
    },
    JENNAIR: {
      WASHER: [],
      DRYER: [],
      COOKING: ['JA Oven']
    }
  }

  const applianceBrandObj = {}
  Object.keys(applianceBrandCategoryObject).map(brand => {
    applianceBrandObj[brand] = {}
    Object.keys(applianceBrandCategoryObject[brand]).map(category => {
      applianceBrandObj[brand][category] = {}
      for (const item in applianceBrandCategoryObject[brand][category]) {
        applianceBrandObj[brand][category][applianceBrandCategoryObject[brand][category][item]] = {
          isSelected: false
        }
      }
    })
  })
  const [applianceBrandObject, setApplianceBrandObject] = useState(applianceBrandObj)
  const onSelectBrand = (e, data) => {
    setBrand(data.value)
  }
  const onSelectCategory = (e, data) => {
    setCategory(data.value)
  }
  const toggleApplianceSelection = (e, data) => {
    if (data.checked) {
      alert.removeAll()
      document.getElementById('select-appliance-text').textContent = ''
      const updatedObj = { ...applianceBrandObject }
      updatedObj[brand][category === undefined ? data.value : category][data.label] = {
        isSelected: data.checked
      }
      setApplianceBrandObject(applianceBrandObject => ({
        ...applianceBrandObject,
        ...updatedObj
      }))
    } else {
      const updatedObj = { ...applianceBrandObject }
      updatedObj[brand][category === undefined ? data.value : category][data.label] = {
        isSelected: data.checked
      }
      setApplianceBrandObject(applianceBrandObject => ({
        ...applianceBrandObject,
        ...updatedObj
      }))
    }
  }
  const setBrands = () => {
    // Currently hardcoded, need to be moved to a config
    const brandOptions = [
      { value: undefined, text: '' },
      { value: 'WHIRLPOOL', text: 'Whirlpool' },
      { value: 'MAYTAG', text: 'Maytag' },
      { value: 'KITCHENAID', text: 'KitchenAid' },
      { value: 'JENNAIR', text: 'JennAir' }
    ]
    return brandOptions
  }
  const setCategories = () => {
    const categoryOptions = [
      { value: undefined, text: '' },
      { value: 'WASHER', text: 'Washer' },
      { value: 'DRYER', text: 'Dryer' },
      { value: 'COOKING', text: 'Cooking' }
    ]
    return categoryOptions
  }

  const resetVirtalApplianceSelections = () => {
    const defaultApplianceBrandObject = { ...applianceBrandObject }
    Object.keys(defaultApplianceBrandObject).map(brand => {
      Object.keys(defaultApplianceBrandObject[brand]).map(category => {
        Object.keys(defaultApplianceBrandObject[brand][category]).map(applianceNames => {
          defaultApplianceBrandObject[brand][category][applianceNames].isSelected = false
        })
      })
    })
    setApplianceBrandObject(applianceBrandObject => ({
      ...applianceBrandObject,
      ...defaultApplianceBrandObject
    }))
  }

  const isAnyApplianceSelected = () => {
    let isSelected = false
    Object.keys(applianceBrandObject).map(brand => {
      Object.keys(applianceBrandObject[brand]).map(category => {
        Object.keys(applianceBrandObject[brand][category]).map(applianceNames => {
          if (applianceBrandObject[brand][category][applianceNames].isSelected === true) {
            isSelected = true
          }
        })
      })
    })
    return isSelected
  }

  const onClickConfirm = useCallback(async () => {
    try {
      if (!isAnyApplianceSelected()) {
        alert.show('Please select at least one appliance.')
        document.getElementById('select-appliance-text').textContent = 'Please select at least one appliance.'
      } else if (await onConfirm(applianceBrandObject)) {
        setBrand(undefined)
        setCategory(undefined)
      }
    } finally {
      resetVirtalApplianceSelections()
    }
  }, [onConfirm, applianceBrandObject])

  return (
    <Modal size='small' open={open} onClose={onClose}>
      <button
        className='modal--www-close-icon-button'
        onClick={onClose}
      >
        <img alt='Close Button Icon' src='/custom-content/images/www-close-icon.svg' />
      </button>
      <Modal.Header>Virtual appliances allowed for this partner</Modal.Header>
      <Modal.Content>
        <div>
          <Select value={brand} placeholder='Brand' options={setBrands()} onChange={onSelectBrand} />&nbsp;&nbsp;
          <Select value={category} placeholder='Category' options={setCategories()} onChange={onSelectCategory} />
        </div>
        <div>
          <br />
          {
            brand && !category && (Object.keys(applianceBrandObject[brand]).map(category => (
              Object.keys(applianceBrandObject[brand][category]).map(applianceName => (
                <div><Checkbox value={category} checked={applianceBrandObject[brand][category][applianceName].isSelected} label={applianceName} onChange={toggleApplianceSelection} /><br /></div>
              ))
            )))
          }
          {
            brand && category && (Object.keys(applianceBrandObject[brand][category]).map(applianceName => (
              <div><Checkbox checked={applianceBrandObject[brand][category][applianceName].isSelected} label={applianceName} onChange={toggleApplianceSelection} /><br /></div>
            )))
          }
        </div>
      </Modal.Content>
      <span id="select-appliance-text"></span>
      <Modal.Actions>
        <input type="button" value="Confirm" id="sign-in-button" onClick={onClickConfirm}></input>
      </Modal.Actions>
    </Modal>
  )
}

const DeleteSuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      Deleted account <strong>{account.EmailAddress}</strong>.
    </Message.Content>
  </Message>
))

const DeleteFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to delete account <strong>{account.EmailAddress}</strong>.
        </p>
        {errorMessage && <p>Error message: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
)

const PromoteSuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      Promoted account <strong>{account.EmailAddress}</strong>.
    </Message.Content>
  </Message>
))

const PromoteFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to promote account <strong>{account.EmailAddress}</strong>.
        </p>
        {errorMessage && <p>Error message: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
)

const AssociateSuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      Associated account <strong>{account.EmailAddress}</strong>.
    </Message.Content>
  </Message>
))

const AssociateFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to associate account <strong>{account.EmailAddress}</strong>.
        </p>
        {errorMessage && <p>Error message: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
)

const DisassociateSuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      Disassociated account <strong>{account.EmailAddress}</strong>.
    </Message.Content>
  </Message>
))

const DisassociateFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to disassociate account <strong>{account.EmailAddress}</strong>. Error: {errorMessage.message}
        </p>
      </Message.Content>
    </Message>
  )
)

const RequestVirtualApplianceSuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      Requested Virtual Appliance for <strong>{account.EmailAddress}</strong>.
    </Message.Content>
  </Message>
))

const RequestVirtualApplianceFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to request Virtual appliance for <strong>{account.EmailAddress}</strong>. Error: {errorMessage.message}
        </p>
      </Message.Content>
    </Message>
  )
)
