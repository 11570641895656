// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'
import '../styles/apis.css'
import { Container, Header, Icon, Select, Button } from 'semantic-ui-react'
import { isRegistered } from 'services/self'
import { updateUsagePlansAndApisList, getApi } from 'services/api-catalog'
import ApisMenu from 'components/ApisMenu'
import SwaggerLayoutPlugin from 'components/SwaggerUiLayout'
import PageWithSidebar from 'components/PageWithSidebar'
import { store } from 'services/state.js'
import { observer, Observer } from 'mobx-react'
import { positions, Provider as AlertProvider, withAlert } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
let consumerId = getUrlSearchParams().get('consumer_id')

let alert

export default observer(class ApisPage extends React.Component {
  containerRef = React.createRef()
  hasRoot = false

  constructor () {
    super()
    window.apisPageComponent = this
    if (!store.apiKey && window.localStorage.getItem('clientAppKey')) {
      store.apiKey = window.localStorage.getItem('clientAppKey')
    }
  }

  componentDidMount () { this.updateApi(true) }
  componentDidUpdate () { this.updateApi(false) }
  componentWillUnmount () { this.containerRef = null }

  updateApi (isInitial) {
    return getApi(this.props.match.params.apiId || 'ANY', true, this.props.match.params.stage, isInitial)
      .then(api => {
        if (this.containerRef == null) return
        const elem = this.containerRef.current
        const isFirstLoad = !this.hasRoot

        this.hasRoot = elem != null
        if (api && elem != null) {
          const cell = {
            shouldPreauthorizeApiKey: false,
            preauthorizeApiKey: () => {
              cell.shouldPreauthorizeApiKey = true
            }
          }
          const swaggerUiConfig = {
            domNode: this.containerRef.current,
            plugins: [SwaggerLayoutPlugin, { components: { SchemesContainer: schemeReplacement } }],
            supportedSubmitMethods: [],
            spec: api.swagger,
            onComplete: () => cell.preauthorizeApiKey()
          }
          if (isRegistered()) {
            delete swaggerUiConfig.supportedSubmitMethods
          }
          const uiHandler = SwaggerUI(swaggerUiConfig)
          cell.preauthorizeApiKey = () => {
            if (store.apiKey) {
              uiHandler.preauthorizeApiKey('api_key', store.apiKey)
            }
            const schemeContainerDiv = document.getElementsByClassName('scheme-container')
            schemeContainerDiv[0].style.background = '#1A2F3C'
          }
          if (cell.shouldPreauthorizeApiKey) {
            cell.preauthorizeApiKey()
          }

          if (isFirstLoad) return updateUsagePlansAndApisList(true)
        }
      })
  }

  render () {
    let errorHeader
    let errorBody

    if (store.apiList.loaded) {
      if (!store.apiList.apiGateway.length && !store.apiList.generic.length) {
        errorHeader = 'No APIs Published'
        errorBody = 'Your administrator hasn\'t added any APIs to your account. Please contact them to publish an API.'
      } else if (!store.api) {
        errorHeader = 'No Such API'
        errorBody = 'The selected API doesn\'t exist.'
      }
    }
    return (
      <PageWithSidebar
        sidebarContent={<ApisMenu path={this.props.match} activateFirst={true} />}
        SidebarPusherProps={{ className: 'swagger-section' }}
      >
        <div className='swagger-ui-wrap' ref={this.containerRef} style={{ padding: '0 20px' }}>
          {errorHeader && errorBody && (
            <>
              <Header as='h2' icon textAlign='center' style={{ padding: '40px 0px' }}>
                <Icon name='warning sign' circular />
                <Header.Content>{errorHeader}</Header.Content>
              </Header>
              <Container text textAlign='justified'>
                <p>{errorBody}</p>
              </Container>
            </>
          )}
        </div>
      </PageWithSidebar>
    )
  }
})

const alertOptions = {
  position: positions.MIDDLE,
  timeout: 0,
  offset: '40px',
  transition: 'scale'
}

function schemeReplacement () {
  let selectedAppName
  Object.keys(store.partnerApps).map(appName => {
    if (store.partnerApps[appName] === store.apiKey) {
      selectedAppName = appName
    }
  })

  if (consumerId) {
    store.appsToConsumerIdsMap[selectedAppName] = consumerId
  }
  store.linkAccountButtonText = store.appsToConsumerIdsMap[selectedAppName] ? 'Relink Account' : 'Link Account'

  if (store.appsToConsumerIdsMap[selectedAppName]) {
    return (
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Observer>
          {() => <div>
            <label style={{ textAlignLast: 'right', color: '#FFFFFF', fontSize: '14px', fontWeight: '100' }}>Select your App to authorize the API calls</label>
            <div style={{ display: 'flex', paddingTop: '10px' }}>
              <AppDropDownSection />&nbsp;&nbsp;
              <GetConsumerIdButton/>
              <label style={{ textAlignLast: 'right', color: '#FFFFFF', fontSize: '14px', fontWeight: '100', paddingLeft: '10px', paddingRight: '5px', paddingTop: '10px' }}>Consumer-id:&nbsp;</label>
              <label style={{ textAlignLast: 'right', color: '#FFFFFF', fontSize: '14px', fontWeight: '100', border: '2px dashed', paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px' }}>
                {store.appsToConsumerIdsMap[selectedAppName]}
              </label>
            </div>
          </div> }
        </Observer>
      </AlertProvider>
    )
  } else {
    return (
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Observer>
          {() => <div>
            <label style={{ textAlignLast: 'right', color: '#FFFFFF', fontSize: '14px', fontWeight: '100' }}>Select your App to authorize the API calls</label>
            <div style={{ display: 'flex', paddingTop: '10px' }}>
              <AppDropDownSection />&nbsp;&nbsp;
              <GetConsumerIdButton/>
            </div>
            <span id="select-app-text"></span>
          </div> }
        </Observer>
      </AlertProvider>)
  }
}

const AppDropDownSection = observer(class AppDropDownComponent extends React.Component {
  componentDidMount () { this.setApps() }
  componentDidUpdate () { this.setApps() }

  setApps = () => {
    const apps = []
    apps.push({ value: undefined, text: 'None' })
    Object.keys(store.partnerApps).map(appName => {
      apps.push({ value: store.partnerApps[appName], text: appName })
    })
    return apps
  }

  handleSelectChange = (data) => {
    // Clear any alerts and error text when user makes a selection
    if (alert != null) {
      alert.removeAll()
    }

    store.apiKey = data.value
    window.apisPageComponent.updateApi(false)
    consumerId = undefined
    schemeReplacement()
    if (document.getElementById('select-app-text') !== null) {
      document.getElementById('select-app-text').textContent = ''
    }
  }

  render () {
    return (<Select value={store.apiKey} placeholder='Select your App' options={this.setApps()} onChange={(e, data) => this.handleSelectChange(data)} style={{ border: '2px solid', fontSize: '14px' }} />)
  }
})

const GetConsumerIdButton = withAlert()(observer(class GetConsumerIdButtonComponent extends React.Component {
  handleClick = () => {
    if (!store.apiKey) {
      alert = this.props.alert // alert is one of GetConsumerIdButton's properties because of withAlert() above.
      alert.show('Please select one of your apps.')
      document.getElementById('select-app-text').textContent = 'Please select one of your apps.'
      return
    }
    window.localStorage.setItem('clientAppKey', store.apiKey)
    location.href = '/login-module?apikey=' + encodeURI(store.apiKey) + '&redirect_uri=' + encodeURI(window.location.origin) + encodeURI(window.location.pathname) + '&partner=' + encodeURI(store.partnerCode) + '&dept=' + encodeURI(store.partnerDivisionName)
  }

  render () {
    return (<Button onClick={() => this.handleClick()} style={{ border: '2px solid', fontSize: '14px', fontWeight: 'bold', backgroundColor: 'white' }} >{store.linkAccountButtonText}</Button>)
  }
}))

function getUrlSearchParams () {
  const queryStringFromUrl = window.location.search
  return new URLSearchParams(queryStringFromUrl)
}
