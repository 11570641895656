import React, { useCallback } from 'react'

import { observer, useLocalStore } from 'mobx-react'
import { store } from 'services/state'
import { Grid, Header, Button, Form, Message, Select } from 'semantic-ui-react'
import * as AccountService from 'services/accounts'
import * as MessageList from 'components/MessageList'

export default observer(function RegisterPartner (props) {
  const [messages, sendMessage] = MessageList.useMessages()
  const localStore = useLocalStore(() => ({
    partnerEmail: '',
    partnerCode: '',
    partnerName: '',
    partnerDivision: '',
    usagePlanId: ''
  }))

  const registerPartner = useCallback(async () => {
    try {
      await AccountService.registerPartnerAccount(localStore.partnerEmail, localStore.partnerName, localStore.partnerCode, localStore.partnerDivision, store.usagePlanId)
      sendMessage(dismiss => (
        <RegisterPartnerSuccessMessage email={localStore.partnerEmail} dismiss={dismiss} />
      ))
    } catch (error) {
      sendMessage(dismiss => (
        <RegisterPartnerFailureMessage
          email={localStore.partnerEmail}
          dismiss={dismiss}
          errorMessage={error}
        />
      ))
    }
  }, [localStore, sendMessage])

  return (
    <Grid container>/
      <Grid.Column style={{ paddingTop: '40px' }}>
        <Grid.Row>
          <Header as='h1'>Create Partner Account</Header><br />
        </Grid.Row>
        <Grid.Row style={{ paddingTop: '10px' }}>
          <Form>
            <MessageList.MessageList messages={messages} />
            <Form.Field>
              <label>Partner Email</label>
              <Form.Input placeholder="Partner Email..." onChange={(e) => {
                localStore.partnerEmail = e.target.value
              }} value={localStore.partnerEmail} width={6}/>
            </Form.Field>
            <Form.Field>
              <label>Partner Name</label>
              <Form.Input placeholder="Partner name..." onChange={(e) => {
                localStore.partnerName = e.target.value
              }} value={localStore.partnerName} width={6}/>
            </Form.Field>
            <Form.Field>
              <label>Partner Code</label>
              <Form.Input placeholder="Partner code..." onChange={(e) => {
                localStore.partnerCode = e.target.value
              }} value={localStore.partnerCode} width={6}/>
            </Form.Field>
            <Form.Field>
              <label>Partner Division</label>
              <Form.Input placeholder="Partner division..." onChange={(e) => {
                localStore.partnerDivision = e.target.value
              }} value={localStore.partnerDivision} width={6}/>
            </Form.Field>
            <Form.Field>
              <label>Tier</label>
              <AppDropDownSection />
            </Form.Field>
            <Button type="submit" positive onClick={registerPartner}>Submit</Button>
          </Form>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  )
})

const RegisterPartnerSuccessMessage = React.memo(({ email, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      Partner invitation sent to <strong>{email}</strong>.
    </Message.Content>
  </Message>
))
const RegisterPartnerFailureMessage = React.memo(
  ({ email, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          Failed to send partner invitation for <strong>{email}</strong>. Error: {errorMessage.message}
        </p>
      </Message.Content>
    </Message>
  )
)
const AppDropDownSection = observer(class AppDropDownComponent extends React.Component {
  componentDidMount () { this.setUsagePlans() }
  componentDidUpdate () { this.setUsagePlans() }
  setUsagePlans = () => {
    const usagePlans = []
    usagePlans.push({ value: undefined, text: 'None' })
    store.usagePlans.map(usagePlan => {
      usagePlans.push({ value: usagePlan.id, text: usagePlan.name })
    })
    return usagePlans
  }

  handleSelectChange = (data) => {
    store.usagePlanId = data.value
  }

  render () {
    return (
      <div>
        <Select value={store.usagePlanId} placeholder='Select usage plan' options={this.setUsagePlans()} onChange={(e, data) => this.handleSelectChange(data)} />
      </div>)
  }
})
