import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'semantic-ui-react'
import '../styles/components/form-text-input.css'

const FormTextInput = ({ type, ...props }) => {
  return (
    <Form.Input
      className='form--text-input'
      type={type}
      {...props}
    />
  )
}

FormTextInput.propTypes = {
  type: PropTypes.oneOf(['text', 'password'])
}

export default FormTextInput
