import React, { useCallback, useState } from 'react'

import { observer, useLocalStore } from 'mobx-react'
import { store } from 'services/state.js'
import { Grid, Header, Input, Button, Select, Label, Loader, Table } from 'semantic-ui-react'
import { getDeviceState } from '../services/device-state'

export default observer(function DeviceState (props) {
  const [cavityStatuses, setCavityStatuses] = useState(null)
  const [deviceStateLoading, setDeviceStateLoading] = useState(false)
  const localStore = useLocalStore(() => ({
    said: '',
    consumerId: '',
    accessToken: ''
  }))

  const loadDeviceState = useCallback(async () => {
    setDeviceStateLoading(true)
    const device = await getDeviceState(localStore.consumerId, localStore.said, store.apiKey, localStore.accessToken)
    setDeviceStateLoading(false)
    if (device) {
      setCavityStatuses(getDeviceStatuses(device))
    } else {
      alert('Failed to fetch device state')
      setDeviceStateLoading(false)
    }
  }, [setCavityStatuses, localStore])

  return (
    <Grid container>
      <Grid.Column style={{ paddingTop: '40px' }}>
        <Grid.Row>
          <Header size="medium">Device State</Header>
        </Grid.Row>

        <Grid.Row style={{ paddingTop: '10px' }}>
          <Label pointing='right'>SAID</Label>
          <Input placeholder="SAID..." onChange={(e) => {
            localStore.said = e.target.value
          }} value={localStore.said}/><br/>
        </Grid.Row>

        <Grid.Row style={{ paddingTop: '10px' }}>
          <Label pointing='right'>Consumer Id</Label>
          <Input placeholder="Consumer Id..." onChange={(e) => {
            localStore.consumerId = e.target.value
          }} value={localStore.consumerId}/>
        </Grid.Row>

        <Grid.Row style={{ paddingTop: '10px' }}>
          <Label pointing='right'>Access Token</Label>
          <Input placeholder="Access Token..." onChange={(e) => {
            localStore.accessToken = e.target.value
          }} value={localStore.accessToken}/>
        </Grid.Row>

        <Grid.Row style={{ paddingTop: '10px' }}>
          <AppDropDownSection />
        </Grid.Row>

        <Grid.Row style={{ paddingTop: '20px' }}>
          <Button type="submit" positive onClick={loadDeviceState}>Get Device State</Button>
        </Grid.Row>
        <br />
        <Grid.Row>
          {<Loader active={deviceStateLoading} inline />}
          {
            cavityStatuses && <Table celled fixed singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Value</Table.HeaderCell>
                  <Table.HeaderCell>Updated Time</Table.HeaderCell>
                </Table.Row>
              </Table.Header>{
                cavityStatuses.map(status => (<Table.Body>
                  <Table.Row>
                    <Table.Cell>{status.name}</Table.Cell>
                    <Table.Cell>{status.value === true ? 'true' : status.value === false ? 'false' : status.value}</Table.Cell>
                    <Table.Cell>{status.updatedTime}</Table.Cell>
                  </Table.Row>
                </Table.Body>))
              }</Table>}
        </Grid.Row>
      </Grid.Column>
    </Grid>
  )
})

const AppDropDownSection = observer(class AppDropDownComponent extends React.Component {
  componentDidMount () { this.setApps() }
  componentDidUpdate () { this.setApps() }

  setApps = () => {
    const apps = []
    apps.push({ value: undefined, text: 'None' })
    Object.keys(store.partnerApps).map(appName => {
      apps.push({ value: store.partnerApps[appName], text: appName })
    })
    return apps
  }

  handleSelectChange = (data) => {
    store.apiKey = data.value
  }

  render () {
    return (
      <div>
        <Label pointing='right'>App</Label>
        <Select value={store.apiKey} placeholder='Select your App' options={this.setApps()} onChange={(e, data) => this.handleSelectChange(data)} />
      </div>)
  }
})

const getDeviceStatuses = (device) => {
  if (!device.cavities) return null
  const response = []
  device.cavities.forEach(cavity => cavity.attributes.forEach(attribute => response.push(attribute)))
  return response
}
