// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { observer } from 'mobx-react'
import { fragments } from 'services/get-fragments'
import { Link } from 'react-router-dom'
import { Segment, Container, Image } from 'semantic-ui-react'
import '../styles/home.css'
import PillButton from 'components/PillButton'
import Card from 'components/Card'

export const HomePage = observer(() => (
  <section id='home-content'>
    <Segment
      style={{ padding: '6.25rem 0px 40px 0px', margin: '0px !important', borderBottom: 'none' }}
      textAlign='left'
      vertical>
      <Container
        className='title-section'
      >
        <div className='title-section--content'>
          <h1>
            {fragments.Home.header}
          </h1>
          <p style={{
            fontWeight: '700', fontSize: '1.625rem', lineHeight: '1.9375rem'
          }}>
            {fragments.Home.tagline}
          </p>
          <Link to='/getting-started' data-testid='gettingStartedLink'>
            <PillButton
              label={fragments.Home.gettingStartedButton}
              large
              positive
              primary
            />
          </Link>
        </div>
        <div className='title-section--image'>
          <Image
            className='home-page-graphic'
            src='/custom-content/images/home-page-graphic.png'
          />
        </div>
      </Container>
    </Segment>
    <Segment
      style={{ padding: '40px 0px', margin: '0 !important' }}
      vertical
    >
      <Container
        className='card-section'
        text
      >
        <Card
          content={fragments.Home.gettingStartedCardText}
          icon={{
            src: `/custom-content/images/${fragments.Home.gettingStartedCardIconFileName}`,
            alt: fragments.Home.gettingStartedCardIconAlt
          }}
        />
        <Card
          content={fragments.Home.apiListCardText}
          icon={{
            src: `/custom-content/images/${fragments.Home.apiListCardIconFileName}`,
            alt: fragments.Home.apiListCardIconAlt
          }}
        />
        <Card
          content={fragments.Home.signInCardText}
          icon={{
            src: `/custom-content/images/${fragments.Home.signInCardIconFileName}`,
            alt: fragments.Home.signInCardIconAlt
          }}
        />
      </Container>
    </Segment>
  </section>
))

export default HomePage
