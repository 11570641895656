import { observer } from 'mobx-react'
import React from 'react'
import '../styles/components/portal-footer.css'

export const PortalFooter = observer(
  class PortalFooter extends React.Component {
    render () {
      return (
        <div id='portal-footer'>
          <span id='portal-footer-text'>
            <span>2022 Whirlpool&reg;/&trade;&copy;. All rights reserved.</span>
          </span>
        </div>
      )
    }
  }
)
