import '../styles/login-module.css'
import { TextArea } from 'semantic-ui-react'
import { useAlert } from 'react-alert'

const legalContent = decodeURIComponent('By connecting to [third party service], you will be granting access and data to [third party service], consistent with [third party service]%27s terms of use and privacy policies. Please review those terms carefully prior to granting access. \n\nWhile we have partnered with [third party service] to enhance your experience [we can customize this], we can%27t guarantee the performance, functionality, or security of [third party service] and are not responsible for any damages or issues arising from your use of [third party service].')

const LoginModule = () => {
  const alert = useAlert()

  return (
    <div id="overall">
      <div id="loaderDiv" style={{ display: 'none' }}>
        <div className="ui active loader"/>
      </div>
      <div id="login" className="main-section">
        <div className="login-module-header">
          <div className="www-logo-in-header">
            <img
              src="custom-content/images/www-logo.svg"
              alt="Works With Whirlpool"
              className="www-logo-img"
            />
          </div>
        </div>
        <div className="central-rectangle">
          <span id="title-text">
            <span>Sign In to link Whirlpool with XPTO</span>
          </span>
          <div id="email-section">
            <label htmlFor="email-input" className="label-for-input">Email</label>
            <input type="text" id="email-input" name="email" placeholder="name@host.com"></input>
          </div>
          <div id="password-section">
            <label htmlFor="password-input" className="label-for-input">Password</label>
            <input type="password" id="password-input" name="password"></input>
            <span id="forgot-your-password-text">
              <span>Forgot your password?</span>
            </span>
          </div>
          <span id="error-message-text"></span>
          <span id="able-to-revoke-text">
            <span>You&apos;ll be able to revoke this access at any time.</span>
          </span>
          <input type="button" onClick={() => validateUsernameAndPassword(alert)} value="Sign in" id="sign-in-button"></input>
        </div>
        <div className="footer">
          <span className="footer-text">
            <span>2022 Whirlpool&reg;/&trade;&copy;. All rights reserved.</span>
          </span>
        </div>
      </div>

      <div id="permission" className="main-section">
        <div className="login-module-header">
          <div className="www-logo-in-header">
            <img
              src="custom-content/images/www-logo.svg"
              alt="Works With Whirlpool"
              className="www-logo-img"
            />
          </div>
        </div>
        <div className="central-rectangle">
          <div id="logos-bar">
            <img
              src="custom-content/images/whirlpool-logo.svg"
              alt="Whirlpool Logo"
              id="whirlpool-logo"
            />
            <img
              src="custom-content/images/connection-symbol.svg"
              alt="Connection Symbol"
              id="connection-symbol"
            />
            <img
              src="custom-content/images/partner-logo-placeholder.svg"
              alt="Partner Logo Placeholder"
              id="partner-logo-placeholder"
            />
          </div>
          <span id="connect-with-title">
            <span>Connect with XPTO</span>
          </span>
          <span id="explanation-wording">
            <span>
              <span>
                <TextArea style={{ width: '340px', height: '150px' }}>{legalContent}</TextArea>
              </span>
              <br></br>
            </span>
          </span>
          <input type="button" onClick={() => allowConnection(alert)} value="Allow" id="allow-button"></input>
          <input type="button" onClick={denyConnection} value="Not now" id="not-now-button"></input>
        </div>
        <div className="footer">
          <span className="footer-text">
            <span>2022 Whirlpool&reg;/&trade;&copy;. All rights reserved.</span>
          </span>
        </div>
      </div>
    </div>
  )
}
export default LoginModule

// A key-value pair should be added eventually for prod.
const DEV_PORTAL_HOSTNAME_TO_API_HOSTNAME_MAP = {
  localhost: 'ow8bd8s274.execute-api.us-east-2.amazonaws.com/Dev',
  'works-with-portal-dev.whrcloud.com': 'ow8bd8s274.execute-api.us-east-2.amazonaws.com/Dev',
  'works-with-portal-qa.whrcloud.com': 'works-with-api-qa.whrcloud.com',
  'works-with-portal-stg.whrcloud.com': 'works-with-api-stg.whrcloud.com',
  'works-with-portal.whrcloud.com': 'works-with-api.whrcloud.com'
}
const fetch = require('node-fetch')

let memberTokenApiResponseJson = null

function validateUsernameAndPassword (alert) {
  alert.removeAll()
  document.getElementById('loaderDiv').style.display = 'block'
  const username = (document.getElementById('email-input').value).trim()
  const password = document.getElementById('password-input').value

  let validationMessage = ''
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username)) {
    validationMessage += 'Please provide a valid email.\n'
  }
  if (password.length < 3) {
    validationMessage += 'Please provide a password of at least three characters.\n'
  }

  if (validationMessage.length === 0) {
    document.getElementById('error-message-text').textContent = ''
    makeObtainMemberTokenRequest(getUrlSearchParams().get('apikey'), username, password, getUrlSearchParams().get('redirect_uri'), getUrlSearchParams().get('partner'), getUrlSearchParams().get('dept'), alert)
  } else {
    alert.show(validationMessage)
    document.getElementById('loaderDiv').style.display = 'none'
    document.getElementById('error-message-text').textContent = validationMessage
  }
}

// Submit username and password to /auth/token API (ObtainMemberToken AWS Lambda) to validate
// those credentials and get the WCloud member token and related info.
const makeObtainMemberTokenRequest = (apiKey, username, password, redirectUri, partner, dept, alert) => {
  const requestPath = `https://${DEV_PORTAL_HOSTNAME_TO_API_HOSTNAME_MAP[window.location.hostname]}/auth/token`

  const requestHeaders = new fetch.Headers()
  requestHeaders.append('language', 'ENG')
  requestHeaders.append('region', 'NAR')
  requestHeaders.append('partner', partner)
  requestHeaders.append('dept', dept)
  requestHeaders.append('x-api-key', apiKey)

  const unencodedBody = {
    username: username, password: password, redirectUri: redirectUri
  }

  const requestOptions = {
    method: 'POST', headers: requestHeaders, body: JSON.stringify(unencodedBody)
  }

  makeFetchRequest(requestPath, requestOptions)
    .then(jsonResult => respondToObtainMemberTokenFetch(jsonResult, alert),
      error => respondToError(error, alert))
}

function respondToObtainMemberTokenFetch (jsonResult, alert) {
  memberTokenApiResponseJson = jsonResult
  if (Object.prototype.hasOwnProperty.call(memberTokenApiResponseJson, 'accessToken')) {
    document.getElementById('login').style.display = 'none'
    document.getElementById('loaderDiv').style.display = 'none'
    document.getElementById('permission').style.display = 'block'
  } else if (Object.prototype.hasOwnProperty.call(memberTokenApiResponseJson, 'message')) {
    respondToError(memberTokenApiResponseJson.message, alert)
  } else {
    respondToError('An unknown error occurred.', alert)
  }
}

// Perform action when someone clicks the Allow button.
function allowConnection (alert) {
  document.getElementById('loaderDiv').style.display = 'block'
  const urlSearchParams = getUrlSearchParams()
  const apiKey = urlSearchParams.get('apikey')
  const redirectUri = urlSearchParams.get('redirect_uri')
  const partner = urlSearchParams.get('partner')
  const dept = urlSearchParams.get('dept')

  const username = document.getElementById('email-input').value

  return makeAccountLinkWithTokenRequest(apiKey, username, redirectUri, partner, dept, alert)
}

// Perform action when someone click the Not Now button.
function denyConnection () {
  document.getElementById('login').style.display = 'block'
  document.getElementById('permission').style.display = 'none'
}

// Call the /account/linkwithtoken API (AccountLinkWithToken AWS Lambda) to link
// the customer's WCloud account with the customer's account with the partner company.
const makeAccountLinkWithTokenRequest = (apiKey, username, redirectUri, partner, dept, alert) => {
  const requestPath = `https://${DEV_PORTAL_HOSTNAME_TO_API_HOSTNAME_MAP[window.location.hostname]}/account/linkwithtoken`

  const requestHeaders = new fetch.Headers()
  requestHeaders.append('language', 'ENG')
  requestHeaders.append('region', 'NAR')
  requestHeaders.append('x-api-key', apiKey)
  requestHeaders.append('partner', partner)
  requestHeaders.append('dept', dept)

  memberTokenApiResponseJson.username = username

  const requestOptions = {
    method: 'POST', headers: requestHeaders, body: JSON.stringify(memberTokenApiResponseJson)
  }

  makeFetchRequest(requestPath, requestOptions)
    .then(jsonResult => respondToAccountLinkFetch(jsonResult, redirectUri),
      error => respondToError(error, alert))
}

function respondToAccountLinkFetch (jsonResult, redirectUri) {
  if (jsonResult.consumerId !== undefined && jsonResult.access_token !== undefined && jsonResult.refresh_token !== undefined) {
    window.location.href = `${encodeURI(redirectUri)}?consumer_id=${encodeURI(jsonResult.consumerId)}&access_token=${encodeURI(jsonResult.access_token)}&refresh_token=${encodeURI(jsonResult.refresh_token)}&token_type=${encodeURI(jsonResult.token_type)}&expires_in=${encodeURI(jsonResult.expires_in)}`
  } else if (jsonResult.consumerId !== undefined && jsonResult.access_token === undefined) {
    window.location.href = `${encodeURI(redirectUri)}?consumer_id=${encodeURI(jsonResult.consumerId)}`
  } else {
    window.location.href = `${encodeURI(redirectUri)}?consumer_id=${encodeURI(jsonResult.message)}`
  }
}

function respondToError (error, alert) {
  alert.show(error.toString())
  document.getElementById('loaderDiv').style.display = 'none'
  document.getElementById('error-message-text').textContent = error
  console.log('error', error)
}

const makeFetchRequest = async (requestUrl, requestOptions) => {
  const response = await fetch(requestUrl, requestOptions)
  const result = await response.text()
  return JSON.parse(result)
}

function getUrlSearchParams () {
  const queryStringFromUrl = window.location.search
  return new URLSearchParams(queryStringFromUrl)
}
